<template>
  <v-card>
    <v-toolbar class="mb-2" color="primary" dark>
      <v-toolbar-title class="text-h5" elevation="20">
        <span class="headline">Editer la tâche</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field v-model="cards.libelle" label="Libellé*" :rules="[libelleRule]"></v-text-field>
        </v-col>
        <v-col> 
          <v-select :items="filteredTaskSupportVision"
           :item-text="item => `${item.carte.libelle}`" 
           multiple
            label="Lier à une ou plusieurs cartes existantes" :value="selectedCard"
            @change="getSelectedLinkedCard"></v-select> 
        </v-col>
        <!-- si tache assigné afficher le v-autocomplete avec l user affécté -->
        <v-col v-if="cards && cards.assignement && cards.assignement.assigned">
          <v-autocomplete :items="users" :item-text="item => `${item.firstname} ${item.lastname}`" label="Assigné à"
            v-model="usersAsssigned">
            <template slot="selection" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete ref="userSelect" :items="users" :item-text="item => `${item.firstname} ${item.lastname}`"
            label="Demandeur*" :value="formatData(usersSelected)" :rules="[userSelectRule]">
            <template slot="selection" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.firstname + ' ' + data.item.lastname }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-select :items="originType" label="Origine*" v-model="cards.originType"
            :rules="[originTypeRule]"></v-select>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center">
        <v-col>
          <v-select :items="devType" label="Type de développement*" v-model="cards.devType"
            :rules="[devTypeRule]"></v-select>
        </v-col>
        <v-col>
          <v-select :items="impactType" label="Type de demande*" v-model="cards.impactType"
            :rules="[impactTypeRule]"></v-select>
        </v-col>
        <v-menu v-if="cards.impactType === 'Urgent'" v-model="showMenu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" :disabled="cards.impactType !== 'Urgent'">
              <v-icon color="red">mdi-calendar</v-icon>
            </v-btn>
          </template>
          <v-date-picker v-model="cards.urgentRequestDate" :locale="$i18n.locale" color="primary"></v-date-picker>
        </v-menu>
        <div v-if="cards.impactType === 'Urgent'" style="width: 10rem;">
          <v-text-field v-model="cards.urgentRequestDate" label="Date requise" readonly></v-text-field>
        </div>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete :items="dataModule" :item-text="item => `${item.module}`" label="Module" multiple
            @change="getModule" :value="selectedModule">
            <template slot="selection" slot-scope="data">
              {{ data.item.module }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete :items="dataSousModule" :item-text="item => `${item.sous_module}`" label="Sous-module"
            multiple @change="getSousModule" :value="selectedSousModule">
            <template slot="selection" slot-scope="data">
              {{ data.item.sous_module }}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" v-if="cards.images && cards.images.length > 0">
          <v-carousel cycle hide-delimiters height="200">
            <v-carousel-item v-for="(img, i) in cards.images" :key="i">
              <v-img :src="generateLogoUrl(img)" contain max-width="100%" max-height="200"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col>
          <editor v-model="cards.description" placeholder="Description de la demande*" :rules="[descriptionRule]"
            @select-attachment="dialogPieceJointe = true"></editor>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeModal">Annuler</v-btn>
      <v-btn color="warning" text @click="editDev" :disabled="!isValidate">
        Editer
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogPieceJointe" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Ajouter une pièce jointe</span>
        </v-card-title>
        <v-card-text>
          <v-container class="d-flex flex-column">
            <v-row>
              <v-col>
                <v-file-input v-model="files" label="Choisir un fichier" @change="handleFilesChange" multiple
                  accept="image/*, application/pdf" type="file" show-size counter clearable name="uploadedfiles"
                  id="uploadedfiles">
                  <template #selection="{ index, text }">
                    <v-chip small label close color="primary" @click:close="deleteChip(index, text)">{{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row v-if="newLogoPreviews.length > 0">
              <v-col v-for="(preview, index) in newLogoPreviews" :key="index" style="overflow-x: auto;">
                <v-img :src="preview" contain max-width="100%" max-height="300"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogPieceJointe = false">Annuler</v-btn>
          <v-btn color="success" text @click="dialogPieceJointe = false">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import UserService from '@/Services/UserService';
import * as Vuex from "vuex";
import ModuleService from "@/Services/SupportVision/ModuleService";
import Images from '@/Utils/Images';
import DeveloppementService from '@/Services/SupportVision/DeveloppementService';

export default {
  name: "editDev",
  components: {
    ClientPickerSelect: () => import("@/Components/Commons/Inputs/Client/ClientPickerSelect"),
    Editor: () => import('@/Components/Commons/Inputs/Editor/Editor.vue')
  },
  props: {
    placeholder: String,
  },
  data() {
    return {
      users: [],
      originType: ['Client', 'Interne', 'Fiche liaison'],
      selectedOriginType: null,
      devType: ['Passerelle', 'Application', 'Interface/Module'],
      selectedDevType: null,
      impactType: ['Travaux unique', 'Release', 'Urgent'],
      selectedImpactType: null,
      showMenu: false,
      urgentRequestDate: null,
      // usersAsssigned: null,
      dataModule: [],
      dataSousModule: [],
      selectedModule: [],
      selectedSousModule: [],
      libelleRule: (v) => !!v || 'Ce champ est requis',
      userSelectRule: (v) => v && v.length > 0 || 'Ce champ est requis',
      originTypeRule: (v) => !!v || 'Ce champ est requis',
      devTypeRule: (v) => !!v || 'Ce champ est requis',
      impactTypeRule: (v) => !!v || 'Ce champ est requis',
      descriptionRule: (v) => !!v || 'Ce champ est requis',
      location: '',
      cards: {},
      dialogPieceJointe: false,
      files: [],
      newLogoPreviews: [],
      dataFile: null,
      previousFiles: [],
      selectedCard: [],
    };
  },
  computed: {
    ...Vuex.mapState(['taskSupportVision', 'taskId']),
    isValidate() {
      return (
        this.cards.libelle &&
        this.cards.userCreate.length > 0 &&
        this.cards.originType &&
        this.cards.devType &&
        this.cards.impactType &&
        ((this.cards.impactType !== 'Urgent') || (this.cards.impactType === 'Urgent' && this.cards.urgentRequestDate))
      )
    },
    usersSelected: {
      get() {
        return this.cards.userCreate;
      },
      set(value) {
        this.cards.userCreate = value;
      }
    },
    usersAsssigned: {
      get() {
        return this.cards.assignement.assignedTo;
      },
      set(value) {
        this.cards.assignement.assignedTo = value;
      }
    }, 
    filteredTaskSupportVision() {
    return this.taskSupportVision.filter(task => task._id !== this.taskId);
  },
  },
  watch: {
    taskId: {
      handler: function (val) {
        if (val) {
          this.loadData();
        }
      },
      immediate: true
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-edit');
    },
    getModule(module) {
      this.selectedModule = module;
    },
    getSousModule(sousModule) {
      this.selectedSousModule = sousModule;
    },
    getSelectedLinkedCard(card) {
      this.selectedCard = card;
    },
    async editDev() {
      const data = new FormData();
      data.append('libelle', this.cards.libelle);
      data.append('userCreate', this.cards.userCreate);
      data.append('originType', this.cards.originType);
      data.append('devType', this.cards.devType);
      data.append('impactType', this.cards.impactType);
      data.append('urgentRequestDate', this.cards.urgentRequestDate);
      data.append('module', this.selectedModule);
      data.append('sous_module', this.selectedSousModule);
      data.append('description', this.cards.description);
      data.append('images', this.cards.images);
      this.dataFile && this.dataFile.forEach((file) => {
        data.append('file', file);
      });
      // si tache assigné
      if (this.cards.assignement && this.cards.assignement.assignedTo) {
        data.append('assignedTo', this.usersAsssigned);
      }
      data.append('linkedCard', this.selectedCard);
      
      await DeveloppementService.editDev(this.taskId, data);
      this.loadData();
      this.$emit('refresh');
      this.closeModal();
    },
    formatData(data) {
      // si data est un tableau
      if (Array.isArray(data)) {
        return data.join();
      } else {
        return data && data.replace(/[\][\]"]+/g, '');
      }
    },
    async loadData() {
      const users = await UserService.getUsers().then((res) => {
        this.users = res;
      });
      const dataModule = await ModuleService.getModules();
      this.dataModule = dataModule.data.map((module) => module.module);
      const dataSousModule = await ModuleService.getSousModules();
      this.dataSousModule = dataSousModule;
      const res = await DeveloppementService.getDev()
      this.cards = res.data.filter(item => item._id === this.taskId)[0].carte;

      // alimentation du select 
      this.selectedModule = this.cards.module && this.cards.module.split(",");
      this.selectedSousModule = this.cards.sous_module && this.cards.sous_module.split(",");
      
      const response = await DeveloppementService.getDevById(this.taskId);
      this.location = response.config.baseURL + response.config.url;
      this.location = this.location.replace(this.taskId, '') 
      
      this.selectedCard = this.cards.linkedCard && this.cards.linkedCard.split(","); 
    },
    generateLogoUrl(img) {
      if (img && img.filename && img.mimetype) {
        return `${this.location}/read-file?id=${img.filename}&mime=${img.mimetype}`;
      }
      return '';
    },
    async handleFilesChange() {
      this.files = []
      const uploadedFiles = document.getElementById('uploadedfiles').files
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (
          this.previousFiles !== undefined &&
          this.previousFiles !== null &&
          this.previousFiles.length <= 0
        ) {
          this.previousFiles.push(uploadedFiles[i])
        } else {
          const index = this.previousFiles.findIndex((x) => x.name === uploadedFiles[i].name)
          if (index >= 0) {
            this.previousFiles.splice(index, 1)
          }
          this.previousFiles.push(uploadedFiles[i])
        }
      }
      this.files = this.previousFiles

      this.newLogoPreviews = [];
      for (let file of this.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newLogoPreviews.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
      this.dataFile = this.files;
    },
    deleteChip(index, text) {
      // Prompt here with text if required
      this.previousFiles.splice(index, 1)
      this.files = this.previousFiles
    },
  },
  async mounted() {
    await this.loadData();
  }
}
</script>
